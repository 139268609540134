import logger from 'src/utils/logger'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'

// config
import { HOST_API } from 'src/config-global'
import { Constants } from '../../../utils/constants'

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API })

export const Authorization_JWT_Key: string = 'Authorization'
export const Slack_Redirection_Key: string = 'slackRedirectAfterLogin'
export const Teams_Redirection_Key: string = 'teamsRedirectAfterLogin'
export const App_Type_Key: string = 'appType'

axiosInstance.interceptors.request.use((req: InternalAxiosRequestConfig) => {
  const Authorization = localStorage.getItem(Authorization_JWT_Key)
  if (Authorization) {
    req.headers.Authorization = `Bearer ${Authorization}`
  }

  return req
})

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    const { headers } = res

    const Authorization = headers[Constants.ACCESS_HEADER] as string

    if (Authorization) {
      localStorage.setItem(Authorization_JWT_Key, Authorization)
    }

    return res
  },
  (error: AxiosError) => {
    const redirectlocation = error?.response?.headers?.redirectlocation as string

    if (error?.response?.status === 403 && redirectlocation) {
      window.location.href = redirectlocation
    }

    if (![401, 403].includes(error.response?.status || 0)) {
      logger.error('axiosInstance error', {}, error)
    }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
)

export default axiosInstance

// ----------------------------------------------------------------------

const matchEndpointRecursively = (url: string, check: string | object): boolean => {
  if (typeof check === 'string') {
    return url === check
  }

  return Object.keys(check).some((k) => matchEndpointRecursively(url, check[k]))
}

export const isUnauthenticatedEndpoint = (url: string): boolean =>
  unauthenticatedEndpoints.some((endpoint) => matchEndpointRecursively(url, endpoint))

export const fetcher = async (
  [url, params = {}]: [string, Record<string, string | undefined | any>],
  config: AxiosRequestConfig = {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const queryString = new URLSearchParams(params).toString()
    const completeUrl = queryString ? `${url}?${queryString}` : url
    const res = await axiosInstance.get(completeUrl, config)
    return res.data
  } catch (error) {
    if (error.statusCode && [401, 403].includes(error.statusCode)) {
      logger.warn('Ignored 401/403 fetcher error', { url, params }, error)
      return null
    }
    logger.error('fetcher error', { url, params }, error as Error)
    throw error
  }
}

export const swrInfFetcher = async (url: string): Promise<any> => {
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    logger.error('swrInfFetcher Error:', { url }, error)
    throw error
  }
}

export const poster = async (
  [url, params = {}]: [string, Record<string, string | undefined | unknown>],
  config: AxiosRequestConfig = {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const res = await axiosInstance.post(url, params, config)
  return res.data
}

// ----------------------------------------------------------------------

const API_V2 = '/api/v2'

export const endpoints = {
  audience: {
    list: `${API_V2}/audience/list`,
    info: `${API_V2}/audience/info`,
    getUsers: `${API_V2}/audience/get-users`,
    checkIfArchivePossible: `${API_V2}/audience/check-if-archive-possible`,
    messaging_info: `${API_V2}/audience/program-audience-messaging-info`,
    update_messaging_info: `${API_V2}/audience/update-program-audience-messaging-info`,
    archive: `${API_V2}/audience/archive`,
    updatePermission: `${API_V2}/audience/update-permission`,
    updateAdmins: `${API_V2}/audience/update-admins`,
    updateOwner: `${API_V2}/audience/update-owner`,
  },
  user: {
    getUser: `${API_V2}/user/get-user`,
    getPublicUser: `${API_V2}/user/get-public-user`,
    getOrganizationUsers: `${API_V2}/user/get-org-users`,
    updateProfile: `${API_V2}/user/update-profile`,
    updateEmailAddress: `${API_V2}/user/send-email-otp`,
    updateEmailPreferences: `${API_V2}/user/update-email-preferences`,
    sendEmailOtp: `${API_V2}/user/send-email-otp`,
    checkEmailExists: `${API_V2}/user/check-email-exists`,
    verifyEmailOtp: `${API_V2}/user/verify-email-otp`,
    getUserFlowSignature: `${API_V2}/user/get-userflow-signature`,
    submitOnboardingInfo: `${API_V2}/user/submit-onboarding-info`,
    updateNextNPSPopupDate: `${API_V2}/user/update-next-nps-popup-date`,
    getLatestConnections: `${API_V2}/user/latest-connections`,
  },
  account: {
    listForUser: `${API_V2}/account/list-for-user`,
    getAccount: `${API_V2}/account/get-account`,
    updateBillingAddress: `${API_V2}/account/update-billing-address`,
    updateCoversNewPrograms: `${API_V2}/account/covers-new-programs`,
    updateAdmins: `${API_V2}/account/update-admins`,
    updateManagers: `${API_V2}/account/update-managers`,
    updateOwner: `${API_V2}/account/update-owner`,
    createAccount: `${API_V2}/account/create-account`,
    initializeCheckout: `${API_V2}/account/initialize-checkout`,
    updateSubscription: `${API_V2}/account/update-subscription`,
    updatePaymentMethod: `${API_V2}/account/update-payment-method`,
    getBillingErrorsForUser: `${API_V2}/account/get-billing-errors-for-user`,
    cancelSubscription: `${API_V2}/account/cancel-subscription`,
    abortCancelSubscription: `${API_V2}/account/abort-cancel-subscription`,
    availableActiveAccountsForProgramCreation: `${API_V2}/account/available-active-accounts-for-program-creation`,
    addProgram: `${API_V2}/account/add-program`,
  },
  utils: {
    fetchPublicEnvironmentVariables: `${API_V2}/utils/fetch-environment-variables`,
  },
  stripe: {
    fetchSetupIntentClientSecret: `${API_V2}/stripe/fetch-setup-intent-secret`,
    createSubscription: `${API_V2}/stripe/create-subscription`,
    confirmIntent: `${API_V2}/stripe/confirm-intent`,
  },
  program: {
    list: `${API_V2}/program/list`,
    list_summary: `${API_V2}/program/list-summary`,
    info_base: `${API_V2}/program/info-base`,
    info: `${API_V2}/program/info`,
    create: `${API_V2}/program/create`,
    templates: `${API_V2}/program/templates`,
    template_info: (templateId: string): string => `${API_V2}/program/template-info?templateId=${templateId}`,
    getUserProgramList: `${API_V2}/program/user-programs-list`,
    pairs_match_info: `${API_V2}/program/pairs-match-info`,
    team_connectedness: `${API_V2}/program/team-connectedness`,
    match_cycle_report: `${API_V2}/program/match-cycle-report`,
    lottery_match_winner_stat: `${API_V2}/program/lottery-match-winner-stat`,
    coffee_maker_prompts_count: `${API_V2}/program/coffee-maker-prompts-count`,
    top_participants: `${API_V2}/program/top-participants`,
    user_list: (programId: string, totalFetched: number, limit: number): string =>
      `${API_V2}/program/user-list?programId=${programId}&totalFetched=${totalFetched}&limit=${limit}`,
    user_list_base_info: `${API_V2}/program/user-list-base-info`,
    update_program_name: `${API_V2}/program/update-name`,
    update_analytics_email_send: `${API_V2}/program/update-analytics-email-send-status`,
    update_program_owner: `${API_V2}/program/update-owner`,
    update_program_admins: `${API_V2}/program/update-admins`,
    un_notified_user_count_of_program: `${API_V2}/program/un-notified-user-count-of-program`,
    update_audiences: `${API_V2}/program/update-audiences`,
    update_intro_messages: `${API_V2}/program/update-intro-messages`,
    update_status: `${API_V2}/program/update-status`,
    check_if_pro_fields_changed: `${API_V2}/program/check-if-pro-fields-changed`,
    initiate_program: `${API_V2}/program/initiate-program`,
    update_creation_step: `${API_V2}/program/update-creation-step`,
    revert_program_to_default_settings: `${API_V2}/program/revert-program-to-default-settings`,
    add_feedback_question_data: `${API_V2}/program/add-feedback-question-data`,
    get_feedback_question_data: `${API_V2}/program/get-feedback-question-data`,
    get_feedback_next_invocation_data: `${API_V2}/program/get-next-program-feedback-invocation-date`,
    get_analytics_pdf_data: `${API_V2}/program/get-analytics-pdf-data`,
  },
  standard_matching: {
    info: `${API_V2}/program/standard-matching/info`,
    update_match_preferences: `${API_V2}/program/standard-matching/update-match-preferences`,
    update_match_schedule_type: `${API_V2}/program/standard-matching/update-match-schedule-type-and-leaderboard`,
    update_match_message: `${API_V2}/program/standard-matching/update-match-message`,
    update_cross_group_info: `${API_V2}/program/standard-matching/update-cross-group-info`,
  },
  lottery_matching: {
    info: `${API_V2}/program/lottery-matching/info`,
    update_match_preferences: `${API_V2}/program/lottery-matching/update-match-preferences`,
    update_match_message: `${API_V2}/program/lottery-matching/update-match-message`,
    update_lottery_host_info: `${API_V2}/program/lottery-matching/update-lottery-host-info`,
  },
  cross_audience_matching: {
    info: `${API_V2}/program/cross-audience-matching/info`,
    update_group_structure: `${API_V2}/program/cross-audience-matching/update-group-structure`,
    update_match_preferences: `${API_V2}/program/cross-audience-matching/update-match-preferences`,
    update_match_schedule_type: `${API_V2}/program/cross-audience-matching/update-match-schedule-type-and-leaderboard`,
    update_match_message: `${API_V2}/program/cross-audience-matching/update-match-message`,
  },
  intra_group_matching: {
    info: `${API_V2}/program/intra-group-matching/info`,
    update_match_preferences: `${API_V2}/program/intra-group-matching/update-match-preferences`,
    update_match_schedule_type: `${API_V2}/program/intra-group-matching/update-match-schedule-type-and-leaderboard`,
    update_match_message: `${API_V2}/program/intra-group-matching/update-match-message`,
    update_groups_info: `${API_V2}/program/intra-group-matching/update-groups-info`,
  },
  coffee_maker: {
    info: `${API_V2}/program/coffee-maker/info`,
    updateCoffeeMakerPreferences: `${API_V2}/program/coffee-maker/update-preferences`,
    getPrompts: `${API_V2}/program/coffee-maker/get-prompts`,
    createPrompt: `${API_V2}/program/coffee-maker/create-prompt`,
    updatePrompt: `${API_V2}/program/coffee-maker/update-prompt`,
    togglePrompt: `${API_V2}/program/coffee-maker/toggle-prompt`,
    changePromptPosition: `${API_V2}/program/coffee-maker/change-prompt-position`,
    shufflePrompts: `${API_V2}/program/coffee-maker/shuffle-prompts`,
    toggleAllPrompts: `${API_V2}/program/coffee-maker/toggle-all-prompts`,
    deletePrompt: `${API_V2}/program/coffee-maker/delete-prompt`,
  },
  feedback_analytics: {
    get_latest_chart_data: `${API_V2}/feedback-analytics/get-latest-chart-data`,
    get_latest_text_input_data: `${API_V2}/feedback-analytics/get-latest-text-input-data`,
    get_latest_cycle_info: `${API_V2}/feedback-analytics/get-latest-cycle-info`,
    get_analytics_csv_data: `${API_V2}/feedback-analytics/get-analytics-csv-data`,
    get_feedback_response_list: `${API_V2}/feedback-analytics/get-feedback-response-list`,
    get_feedback_question_response_overtime: `${API_V2}/feedback-analytics/get-feedback-question-response-overtime`,
  },
  matchCycles: {
    program_match_cycles: `${API_V2}/match-cycle/get-program-match-cycles`,
    user_match_cycle_search: `${API_V2}/match-cycle/search-user`,
  },
  ai: {
    text_summarization: `${API_V2}/ai/text-summarization`,
  },
  match: {
    add_met_match_reply: `${API_V2}/matches/add-met-match-reply`,
    get_last_match_for_user: `${API_V2}/matches/last-match-for-user`,
  },
  auth: {
    microsoft: {
      login: `${API_V2}/auth/microsoft/login`,
    },
    slack: {
      login: `${API_V2}/auth/slack/login`,
    },
    tab: {
      verify_token: `${API_V2}/bot-tab/verify-token`,
    },
    logout: `${API_V2}/auth/logout`,
  },
  admin: {
    authenticate: `${API_V2}/admin-console/authenticate`,
    endSession: `${API_V2}/admin-console/end-session`,
    currentUser: `${API_V2}/admin-console/current-user`,
    command: `${API_V2}/admin-console/command`,
  },
  aws: {
    getSignedS3PostRequest: `${API_V2}/aws/get-signed-s3-post-request`,
    getSignedS3PostRequestPublic: `${API_V2}/aws/get-signed-s3-post-request-public`,
    getSignedImageUrl: `${API_V2}/aws/signed-image-url`,
  },
  organization: {
    get: `${API_V2}/organization`,
    application_permission_email_to_admin: `${API_V2}/organization/application-permission-email-to-admin`,
  },
  programUserSettings: {
    get: `${API_V2}/program-user-settings`,
    toggleMatching: `${API_V2}/program-user-settings/toggle-matching`,
    updateMatchGroups: `${API_V2}/program-user-settings/update-match-groups`,
    updatePauseMatchingUntil: `${API_V2}/program-user-settings/update-pause-matching-until`,
    updatePreferredMeetingTimes: `${API_V2}/program-user-settings/update-preferred-meeting-times`,
    updateExcludeUserIds: `${API_V2}/program-user-settings/update-exclude-user-ids`,
    toggleCoffeeMakerNotifications: `${API_V2}/program-user-settings/toggle-coffee-maker-notifications`,
    updateOptInPreference: `${API_V2}/program-user-settings/update-opt-in-preference`,
  },
  announcement: {
    get: `${API_V2}/announcement`,
  },
  nps: {
    storeResponse: `${API_V2}/nps/store-response`,
  },
  permissions: {
    info: `${API_V2}/permissions`,
  },
}

const unauthenticatedEndpoints = [
  endpoints.auth,
  endpoints.organization,
  endpoints.user.getUser,
  endpoints.utils.fetchPublicEnvironmentVariables,
]
